// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blocus-assiste-js": () => import("../src/pages/blocus-assiste.js" /* webpackChunkName: "component---src-pages-blocus-assiste-js" */),
  "component---src-pages-coaching-scolaire-js": () => import("../src/pages/coaching-scolaire.js" /* webpackChunkName: "component---src-pages-coaching-scolaire-js" */),
  "component---src-pages-cours-particuliers-js": () => import("../src/pages/cours-particuliers.js" /* webpackChunkName: "component---src-pages-cours-particuliers-js" */),
  "component---src-pages-ecole-des-devoirs-js": () => import("../src/pages/ecole-des-devoirs.js" /* webpackChunkName: "component---src-pages-ecole-des-devoirs-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orientation-scolaire-js": () => import("../src/pages/orientation-scolaire.js" /* webpackChunkName: "component---src-pages-orientation-scolaire-js" */),
  "component---src-pages-qui-sommes-nous-js": () => import("../src/pages/qui-sommes-nous.js" /* webpackChunkName: "component---src-pages-qui-sommes-nous-js" */),
  "component---src-pages-semaine-de-soutien-scolaire-js": () => import("../src/pages/semaine-de-soutien-scolaire.js" /* webpackChunkName: "component---src-pages-semaine-de-soutien-scolaire-js" */),
  "component---src-pages-stage-d-etude-js": () => import("../src/pages/stage-d-etude.js" /* webpackChunkName: "component---src-pages-stage-d-etude-js" */)
}

